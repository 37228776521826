import React from "react";
import { graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import { FinancialSection } from "../components/Screens/Home/FinancialSection";
import Layouts from "../components/Layouts";
import Seo from "../components/Seo";

import { ProductSection } from "../components/Screens/Home/ProductSection";
import { WorkingSteps } from "../components/Screens/Home/WorkingSteps";
import { Testmonials } from "../components/Screens/Home/Testmonials";
import { LatestNewsArticle } from "../components/Screens/Home/LatestNewsArticle";
import { LatestBlogArticles } from "../components/Screens/Home/LatestBlogArticles";
import { BubblesStatics } from "../components/BubblesStatics";
import { useLatestArticles } from "../hooks/useLatestArticles";
// import RequestCardModal from "../components/RequestCardModal";

const IndexPage = ({ data }) => {
	const page = data.page;
	const {
		allWpPost: { edges },
	} = useLatestArticles();
	// Wordpress Quotes
	const testmonialsQuotes = {
		quoteOne: {
			image: page.testomnialsHome.imageQuotes.quoteOneImage,
			name: page.testomnialsHome.imageQuotes.quoteOneName,
			title: page.testomnialsHome.imageQuotes.quoteOne,
		},
		quoteTwo: {
			image: page.testomnialsHome.imageQuotes.quoteTwoImage,
			name: page.testomnialsHome.imageQuotes.quoteTwoName,
			title: page.testomnialsHome.imageQuotes.quoteTwo,
		},
	};

	const productsCards = [
		{
			title: page.productsHome.firstCardProducts.header,
			text: (
				<>
					Reliable. Paperless. Seamless. Hassle-free. <br /> All you need to
					apply is an ID and a face!
				</>
			),
			image: page.productsHome.firstCardProducts.image,
			button: (
				<Link to="/products" className="w-full h-full">
					<motion.button
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						whileHover={{ x: -10 }}
						transition={{ duration: 1, type: "spring", stiffness: 50 }}
						variants={{
							visible: { x: 0, opacity: 1 },
							hidden: { x: 100, opacity: 0 },
						}}
						className={
							"py-5 px-8 rounded-2xl bg-[#F6B904]  drop-shadow-2xl  flex items-center text-white font-bold mt-8"
						}
					>
						<span className="mr-4 md:mr-20 rtl:mr-0 rtl:ml-4  md:rtl:ml-20">
							Get a Loan
						</span>
						<div className="rtl:transform rtl:rotate-180">{Arrow}</div>
					</motion.button>
				</Link>
			),
		},
		// {
		// 	title: page.productsHome.secondCardProducts.header,
		// 	text: page.productsHome.secondCardProducts.text,
		// 	image: page.productsHome.secondCardProducts.image,
		// 	// button: <RequestCardModal />,
		// 	// buttonClassName: "bg-[#1E1E1E]",
		// 	comingSoon: true,
		// },
	];

	const workingCards = {
		cardOne: {
			title: page.howItWorks.howitworksteps.stepOneTitle,
			text: page.howItWorks.howitworksteps.stepOneText,
		},
		cardTwo: {
			title: page.howItWorks.howitworksteps.stepTwoTitle,
			text: page.howItWorks.howitworksteps.stepTwoText,
		},
		cardThree: {
			title: page.howItWorks.howitworksteps.stepThreeTitle,
			text: page.howItWorks.howitworksteps.stepThreeText,
		},
		cardFour: {
			title: page.howItWorks.howitworksteps.stepFourTitle,
			text: page.howItWorks.howitworksteps.stepFourText,
		},
	};
	return (
		<>
			<script
				dangerouslySetInnerHTML={{
					__html: `
		(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "k6sz4xa64c");

        `,
				}}
			/>

			<Seo title="Home" />
			<Layouts lang="en">
				<FinancialSection
					miniHeader={page.heroHome.miniHeader}
					header={page.heroHome.header}
					text={page.heroHome.text}
					googleLink={page.heroHome.googlePlayLink}
					appGalleryLink={page.heroHome.appGallery}
					slider={page.heroHome.slider}
				/>

				<ProductSection
					miniHeader={page.productsHome.miniHeaderProducts}
					header={page.productsHome.headerProducts}
					subHeader={page.productsHome.textProducts}
					cards={productsCards}
				/>
				<WorkingSteps
					miniHeader={page.howItWorks.miniHeaderSteps}
					header={
						<>
							Fully digital, <br /> from start to finish.
						</>
					}
					subHeader="Get everything done through the mobile app."
					cards={workingCards}
					image={page.howItWorks.howItWorksImage}
				/>
				<Testmonials
					miniHeader={page.testomnialsHome.miniHeaderTestimonials}
					header={
						<>
							Success Stories <br /> from Real Customers
						</>
					}
					subHeader={page.testomnialsHome.textTestimonials}
					videoUrl={page.testomnialsHome.videoUrl}
					image={page.testomnialsHome.imageTestimonials}
					quotes={testmonialsQuotes}
					testmonialsItems={page.testomnialsHome.testimonialsQuotes}
					testmonialsHeader="Small loans, big impact!"
				/>
				<LatestNewsArticle
					miniHeader={page.lastNewsBannerHome.new.miniHeader}
					header={page.lastNewsBannerHome.new.header}
					text={page.lastNewsBannerHome.new.text}
					buttonText="Read More"
					link={page.lastNewsBannerHome.new.link}
					image={page.lastNewsBannerHome.new.image}
				/>
				{/* <LatestBlogArticles
					miniHeader="Blog"
					header="Latest Articles"
					blogPosts={edges}
					lang="en"
				/> */}
				<BubblesStatics kpis={page.kpis.kpis} />
			</Layouts>
		</>
	);
};
export default IndexPage;

export const pageQu = graphql`
	query Home {
		page: wpPage(databaseId: { eq: 86 }) {
			title
			heroHome {
				miniHeader
				header
				text
				appGallery
				googlePlayLink
				slider {
					image: sliderImage {
						link
						date
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
				}
			}
			productsHome {
				miniHeaderProducts
				headerProducts
				textProducts
				firstCardProducts {
					header
					text
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 400, placeholder: BLURRED)
							}
						}
					}
				}
				secondCardProducts {
					header
					text
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 320, placeholder: BLURRED)
							}
						}
					}
				}
			}
			howItWorks {
				miniHeaderSteps
				headerSteps
				textSteps
				howItWorksImage {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1024, placeholder: BLURRED)
						}
					}
				}
				howitworksteps {
					stepOneTitle
					stepOneText
					stepTwoTitle
					stepTwoText
					stepThreeTitle
					stepThreeText
					stepFourTitle
					stepFourText
				}
			}
			testomnialsHome {
				miniHeaderTestimonials
				headerTestimonials
				textTestimonials
				videoUrl
				testimonialsQuotes {
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1024, placeholder: BLURRED)
							}
						}
					}
					quote
					text
					name
				}
				imageTestimonials {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1024, placeholder: BLURRED)
						}
					}
				}
				imageQuotes {
					quoteOne
					quoteOneName
					quoteOneImage {
						sourceUrl
					}
					quoteTwo
					quoteTwoName
					quoteTwoImage {
						sourceUrl
					}
				}
			}
			lastNewsBannerHome {
				new {
					miniHeader
					header
					text
					link
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
				}
			}
			kpis {
				kpis {
					title
					downloads
					accountregisterd
					loanrequested
				}
			}
		}
	}
`;
const Arrow = (
	<svg
		width="7"
		height="12"
		viewBox="0 0 7 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1 1L6 6L1 11"
			stroke="white"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
